// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.checkoutCompleteContainer {
  width: '100%';
  height: 100vh;
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.checkoutCompleteHeader {
  color: white;
  font-family: 'Roboto', sans-serif;
}

.checkoutCompleteText {
  color: white;
  font-family: 'Roboto', sans-serif;
  text-align: center;
}

.checkoutCompleteButton {
  margin-top: 1rem;
  width: 8rem;
  height: 2.5rem;
  font-family: 'Roboto', sans-serif;
  font-weight: normal;
  border-radius: 10px;
  font-size: medium;
  border: 0;
  background-color: #0056b3;
  color: white;
  transform: scale(1.0);
}

.checkoutCompleteButton:hover {
  cursor: pointer;
  transform: scale(1.05);
}`, "",{"version":3,"sources":["webpack://./src/css/CheckoutComplete.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;EACb,yBAAyB;EACzB,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,iCAAiC;AACnC;;AAEA;EACE,YAAY;EACZ,iCAAiC;EACjC,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,WAAW;EACX,cAAc;EACd,iCAAiC;EACjC,mBAAmB;EACnB,mBAAmB;EACnB,iBAAiB;EACjB,SAAS;EACT,yBAAyB;EACzB,YAAY;EACZ,qBAAqB;AACvB;;AAEA;EACE,eAAe;EACf,sBAAsB;AACxB","sourcesContent":[".checkoutCompleteContainer {\n  width: '100%';\n  height: 100vh;\n  background-color: #282c34;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n}\n\n.checkoutCompleteHeader {\n  color: white;\n  font-family: 'Roboto', sans-serif;\n}\n\n.checkoutCompleteText {\n  color: white;\n  font-family: 'Roboto', sans-serif;\n  text-align: center;\n}\n\n.checkoutCompleteButton {\n  margin-top: 1rem;\n  width: 8rem;\n  height: 2.5rem;\n  font-family: 'Roboto', sans-serif;\n  font-weight: normal;\n  border-radius: 10px;\n  font-size: medium;\n  border: 0;\n  background-color: #0056b3;\n  color: white;\n  transform: scale(1.0);\n}\n\n.checkoutCompleteButton:hover {\n  cursor: pointer;\n  transform: scale(1.05);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
