import { useLocation, useNavigate } from "react-router-dom"
import { useParams } from 'react-router-dom'
import { useEffect, useRef, useState } from "react"
import { checkAudioRecordingTimeout, checkDecibelLevel, exportBufferGoogle, recordingTimeout, setCheckAudioRecordingTimeout, setRecordingTimeout } from "../../utilities/audio.js"
import { apiURL } from "../../utilities/URLs.js"
import { LoginCreds } from "../../utilities/loginCreds.js"
import { Characters } from "../../utilities/Characters.js"
import { formatNumber } from "../../utilities/Extensions.js"
import { ConfirmReport, NoMoreInteractions, RegisterForAnAccount, SignInToLike } from "../../utilities/PopUpWindows.js"
import CharacterModal from "../CharacterModal.js"
import Recorder from 'recorder-js'

import '../../css/CharacterPage.css'
import '../../css/CharacterPage-Mobile.css'
import '../../css/TranslateDemo.css'

import likedIcon from '../../assets/icons/likedIcon.png'
import notLikedIcon from '../../assets/icons/notLikedIcon.png'
import userPfp from '../../assets/icons/BlankProfilePicture.png'
import backButton from '../../assets/icons/backButton.png'
import clickSound from '../../assets/sounds/clickSound.mp3'
import { demoCharacters, Languages, LanguageToChinese } from "../../utilities/Languages.js"

function TranslateDemo() {

  const navigate = useNavigate()
  const location = useLocation()

  const { characterId } = useParams()

  const [characters, setCharacters] = useState([])
  const [currentCharacterLikeCount, setCurrentCharacterLikeCount] = useState(location.state ? (location.state.character.characterLikeCount ? location.state.character.characterLikeCount : location.state.character.userPersonaLikeCount) : 0)
  if(currentCharacterLikeCount === undefined)
    setCurrentCharacterLikeCount(0)

  const [loadingCharacter, setLoadingCharacter] = useState(true)
  const [currentCharacter, setCurrentCharacter] = useState(null)
  const [sessionToken, setSessionToken] = useState('')
  const [voiceId, setVoiceID] = useState('')

  const [userMessage, setUserMessage] = useState('')
  const [messages, setMessages] = useState([])

  const [loading, setLoading] = useState('')
  const [error, setError] = useState('')
  const [isRecording, setRecording] = useState(false)

  const [microphoneStream, setMicrophoneStream] = useState(null)
  const [recorder, setRecorder] = useState(null)
  const [analyser, setAnalyser] = useState(null)

  const audioRef = useRef(null)

  const timerRef = useRef(null)

  const [characterModalIsOpen, setCharacterModalIsOpen] = useState(false)

  const [noMoreInteractions, setNoMoreInteractions] = useState(false)
  const [registerForAnAccount, setRegisterForAnAccount] = useState(false)
  const [signInToLike, setSignInToLike] = useState(false)
  const [showReport, setShowReport] = useState(false)

  const [showLanguageSelect, setShowLanguageSelect] = useState(true)
  const [maxSessionsReached, setShowMaxSessionsReached] = useState(false)

  // variables for language in and out
  let [languageIn, setLanguageIn] = useState('')
  let [languageOut, setLanguageOut] = useState('')
  let [langCode, setLangCode] = useState('')

  // Handle language in change
  const handleLanguageInChange = (e) => {
    setLanguageIn(e.target.value);
    setLangCode(Languages[e.target.value])
  };

  // Handle language out change
  const handleLanguageOutChange = (e) => {
    setLanguageOut(e.target.value);
  };

  // news index
  let [newsIndex, setNewsIndex] = useState(0)

  // boolean for message type
  let [messageType, setMessageType] = useState(true)

  // function to handle message type change
  const handleMessageTypeChange = (e) => {
    let boolOut = e === 'chat' ? true : false
    let loadingText = e === 'chat' ? 'Chatting Mode Active' : 'Translation Mode Active' 
    let chatButton = document.getElementById('chatButton')
    let translateButton = document.getElementById('translateButton')
    if (e === 'chat') {
      chatButton.style.backgroundColor = 'blue'
      // change to hex color #201c1c

      translateButton.style.backgroundColor = '#201c1c'
    } else {
      chatButton.style.backgroundColor = '#201c1c'
      translateButton.style.backgroundColor = 'blue'
    }
    setLoading(loadingText)
    setMessageType(boolOut)
  }

  const handleReport = (e) => {
    e.stopPropagation()
    setShowReport(true)
  }

  window.addEventListener('beforeunload', () => {
    if(characterId)
      closeSession()
    else
      closeSessions()
  })

  useEffect(() => {
    const fetchCharacters = async () => {
      try {
        setLoadingCharacter(true)
        
        const fetchPromises = demoCharacters.map(async (characterObj) => {
          const character = Characters.allCharacters.find((char) => 
          characterObj.characterId === char.characterId || characterObj.characterId === char.userPersonaId
          )

          if (character === undefined)
            return null

          const API_URL = `${apiURL}/getSessionToken?userName=Guest&accountToken=${LoginCreds.devToken}&isCustomizedCharacter=1&characterId=${character.characterId}&audio=0&emotion=0`
          
          const response = await fetch(API_URL)
          const data = await response.json()

          if (data.response.error) {
            setError(data.response.error)
            return null
          } else {
            return {
              character: character,
              sessionToken: data.response.sessionToken,
              voiceId: characterObj.voiceId,
              defaultLang: characterObj.defaultLang,
              messages: []
            }
          }
        })

        const resolvedCharacters = await Promise.all(fetchPromises)
        const validCharacters = resolvedCharacters.filter(character => character !== null)

        if(validCharacters.length > 0) {
          setCharacters(validCharacters)
          setSessionToken(validCharacters[0].sessionToken)
          setVoiceID(validCharacters[0].voiceId)
          setMessages(validCharacters[0].messages)
          setLanguageIn(validCharacters[0].defaultLang ? validCharacters[0].defaultLang : '')
          setLangCode(validCharacters[0].defaultLang ? Languages[validCharacters[0].defaultLang] : '')
          setLanguageOut(validCharacters[0].defaultLang ? validCharacters[0].defaultLang : '')
          setCurrentCharacter(validCharacters[0].character)
        } else {
          setShowMaxSessionsReached(true)
        }
      } catch (error) {
        console.error("An error occurred:", error)
      } finally {
        setLoadingCharacter(false)
      }
    }

    const fetchCharacter = async () => {
      try {
        const character = Characters.allCharacters.find((char) => 
          characterId === char.characterId || characterId === char.userPersonaId
        )

        const demoCharacterObj = demoCharacters.find((demoChar) =>
          characterId === demoChar.characterId 
        )

        const API_URL = `${apiURL}/getSessionToken?userName=Guest&accountToken=${LoginCreds.devToken}&isCustomizedCharacter=1&characterId=${character.characterId}&audio=0&emotion=0`
          
        const response = await fetch(API_URL)
        const data = await response.json()

        if (data.response.error) {
          setError(data.response.error)
          if(data.response.error === 'Maximum Number Of Sessions Reached')
            setShowMaxSessionsReached(true)
        } else {
          setSessionToken(data.response.sessionToken)
          setVoiceID(demoCharacterObj.voiceId)
          setMessages([])
          setLanguageIn(demoCharacterObj.defaultLang ? demoCharacterObj.defaultLang : '')
          setLangCode(demoCharacterObj.defaultLang ? Languages[demoCharacterObj.defaultLang] : '')
          setLanguageOut(demoCharacterObj.defaultLang ? demoCharacterObj.defaultLang : '')
          setCurrentCharacter(character)
          setLoadingCharacter(false)
        }        
      } catch {
        console.error('An error occured: '+error)
      }
    }

    const initMicrophone = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
        const audioCtx = new (window.AudioContext)()
        const source = audioCtx.createMediaStreamSource(stream)

        const analyser = audioCtx.createAnalyser()
        analyser.fftSize = 2048

        source.connect(analyser)

        const recorder = new Recorder(audioCtx)

        setMicrophoneStream(stream)
        setRecorder(recorder)
        setAnalyser(analyser)
      } catch (error) {
        console.error('Error accessing microphone: ', error)
      }
    }

    if(characterId)
      fetchCharacter()
    else
      fetchCharacters()
    initMicrophone()

    return () => {
      if (microphoneStream) microphoneStream.getTracks().forEach((track) => track.stop())
    }
  }, [])


  useEffect(() => {
    document.documentElement.scrollTop = document.documentElement.scrollHeight
    var messageBody = document.getElementById('chat_box')
    if(messageBody)
      messageBody.scrollTop = messageBody.scrollHeight
  }, [messages])

  const handleSendMessage = () => {
    // if language in or out is not selected, return
    if (languageIn === '' || languageOut === '') {
      alert('Please select a language in and out')
      return
    }
    if (audioRef.current !== null) {
      audioRef.current.pause()
    }
    if(userMessage !== '') {
      setUserMessage('')
      setError('')
      setMessages((prevMessages) => [...prevMessages, {characterId: 0, message: userMessage}])
      setLoading('Sending Message . . .')
      if (messageType) {
        fetch(`${apiURL}/sendMessageTranslate?message=${userMessage}&srcLang=${languageIn}&tgtLang=${languageOut}&token=${sessionToken}&voiceId=${voiceId}`)
        .then((res) => res.json())
        .then((data) => {
          if(data.response.error) {
            if(data.response.error === 'No More Interactions Left For The Month')
              setNoMoreInteractions(true)
            setLoading(data.response.error)
          } else {
            setLoading('')
            handleAddMessage(data.response.messages, 0)
          }
        })
      } else {
        fetch(`${apiURL}/translateMessage?message=${userMessage}&srcLang=${languageIn}&tgtLang=${languageOut}&voiceId=${voiceId}`)
        .then((res) => res.json())
        .then((data) => {
          if(data.response.error) {
            if(data.response.error === 'No More Interactions Left For The Month')
              setNoMoreInteractions(true)
            setLoading(data.response.error)
          } else {
            setLoading('')
            // put newsclipping.translatedNewsClipping and newsclipping.audioChunk into an array
            let translatedMessage = data.response.message
            let newsMessages = []
            newsMessages.push({characterId: 1, message: translatedMessage.translatedText, audioChunk: translatedMessage.audioChunk})

            handleAddMessage(newsMessages, 0)
          }
        })
      }
    } else
      setError('Please Enter A Message')
  }

  const closeSession = () => {
    if(timerRef)
      clearTimeout(timerRef.current)

    fetch(`${apiURL}/closeSession?token=${sessionToken}`)
    .then((res) => res.json())
    .then(() => navigate('/'))
  }

  const closeSessions = () => {
    if(timerRef)
      clearTimeout(timerRef.current)

    const closeSessionPromises = characters.map(async character => {
      const res = await fetch(`${apiURL}/closeSession?token=${character.sessionToken}`)
      return await res.json()
    })
    
    Promise.all(closeSessionPromises)
  }

  const handleAddMessage = (newMessages, index) => {
    if(audioRef.current !== null) {
      if(index < newMessages.length) {
        setMessages((prevMessages) => [...prevMessages, newMessages[index]])

        audioRef.current.src = 'data:audio/wav;base64,'+newMessages[index].audioChunk
        audioRef.current.play()
        audioRef.current.onended = () => {
          handleAddMessage(newMessages, index+1)
        }
      }
    }
  }

  const handleKeyEvent = (key) => {
    if(key === 'Enter')
      handleSendMessage()
  }

  const startRecording = () => {
    // if language in or out is not selected, return
    if (languageIn === '' || languageOut === '') {
      alert('Please select a language in and out')
      return
    }

    new Audio(clickSound).play()
    setRecording(true)

    const timeoutId = setTimeout(() => {
      stopRecording()
    }, 10000)

    setRecordingTimeout(timeoutId)

    const checkAudioTimeoutId = setTimeout(() => {
      checkDecibelLevel(analyser, () => {
        autoStopRecording()
      })
    }, 2000)

    setCheckAudioRecordingTimeout(checkAudioTimeoutId)

    recorder.init(microphoneStream)
    recorder.start()
  }

  const stopRecording = async () => {
    new Audio(clickSound).play()
    if(recordingTimeout !== null) {
      clearTimeout(recordingTimeout)
      setRecordingTimeout(null)
    }

    if(checkAudioRecordingTimeout !== null) {
      clearTimeout(checkAudioRecordingTimeout)
      setCheckAudioRecordingTimeout(null)
    }

    setRecording(false)
    const buffer = await recorder.stop()

    const audio = exportBufferGoogle(buffer.buffer[0])

    sendAudioBlob(audio)
  }

  const autoStopRecording = async () => {
    new Audio(clickSound).play()
    if(recordingTimeout !== null) {
      clearTimeout(recordingTimeout)
      setRecordingTimeout(null)
    }

    if(checkAudioRecordingTimeout !== null) {
      clearTimeout(checkAudioRecordingTimeout)
      setCheckAudioRecordingTimeout(null)
    }

    await recorder.stop()
    setRecording(false)
  }

  const sendAudioBlob = async (audio) => {
    setLoading('Loading . . .')
    setError('')
    const audioParams = {
      langCode: langCode,
      srcLang: languageIn,
      tgtLang: languageOut
    }
    const formData = new FormData()
    formData.append('audioParams', JSON.stringify(audioParams))
    formData.append('audio', audio, 'audio.wav')

    if (messageType) {
      fetch(`${apiURL}/sendAudioMessageTranslate?token=${sessionToken}&voiceId=${voiceId}`, {
        method: 'POST',
        body: formData
      })
      .then((res) => res.json())
      .then((data) => {
        if(data.response.error) {
          if(data.response.error === 'No More Interactions Left For The Month')
            setNoMoreInteractions(true)
          setLoading(data.response.error)
        } else {
          setLoading('')
  
          setMessages((prevMessages) => [...prevMessages, {characterId: 0, message: data.response.userMessage.message}])

          handleAddMessage(data.response.messages, 0)
        }
      })
    } else {
      fetch(`${apiURL}/translateAudioMessage?voiceId=${voiceId}`, {
        method: 'POST',
        body: formData
      })
      .then((res) => res.json())
      .then((data) => {
        if(data.response.error) {
          if(data.response.error === 'No More Interactions Left For The Month')
            setNoMoreInteractions(true)
          setLoading(data.response.error)
        } else {
          setLoading('')
  
          setMessages((prevMessages) => [...prevMessages, {characterId: 0, message: data.response.message.userMessage}])
          let translatedMessage = data.response.message
          let newsMessages = []
          newsMessages.push({characterId: 1, message: translatedMessage.translatedText, audioChunk: translatedMessage.audioChunk})
          handleAddMessage(newsMessages, 0)
        }
      })
    }

  }

  const closeSessionAndEditCharacter = () => {
    fetch(`https://midlayer.ndsprj.com/closeSession?token=${sessionToken}`)
    .then((res) => res.json())
    .then((data) => navigate('/EditCharacter', { state: { character: currentCharacter } }))
  }

  const handleCloseModal = () => {
    setCharacterModalIsOpen(false)
  }

  const handleSendLike = () => {
    fetch(`${apiURL}/sendLike?devToken=${LoginCreds.devToken}&characterId=${currentCharacter.characterId ? currentCharacter.characterId : currentCharacter.userPersonaId}`)
    .then((res) => res.json())
    .then((data) => {
      currentCharacter.characterLikeCount ? currentCharacter.characterLikeCount = data.response.updatedLikeCount : currentCharacter.userPersonaLikeCount = data.response.updatedLikeCount
      setCurrentCharacterLikeCount(currentCharacter.characterLikeCount ? currentCharacter.characterLikeCount : currentCharacter.userPersonaLikeCount)

      if(Characters.allCharacters.length > 0) {
        const characterIndex = Characters.allCharacters.findIndex((char) => char.characterId ? char.characterId === (currentCharacter.characterId ? currentCharacter.characterId : currentCharacter.userPersonaId) : char.userPersonaId === (currentCharacter.characterId ? currentCharacter.characterId: currentCharacter.userPersonaId))
        Characters.allCharacters[characterIndex].characterLikeCount = data.response.updatedLikeCount
      }

      LoginCreds.userLikes.push(currentCharacter.characterId ? currentCharacter.characterId : currentCharacter.userPersonaId)
    })
  }

  const handleRemoveLike = () => {
    fetch(`${apiURL}/removeLike?devToken=${LoginCreds.devToken}&characterId=${currentCharacter.characterId ? currentCharacter.characterId : currentCharacter.userPersonaId}`)
    .then((res) => res.json())
    .then((data) => {
      currentCharacter.characterLikeCount ? currentCharacter.characterLikeCount = data.response.updatedLikeCount : currentCharacter.userPersonaLikeCount = data.response.updatedLikeCount
      setCurrentCharacterLikeCount(currentCharacter.characterLikeCount ? currentCharacter.characterLikeCount : currentCharacter.userPersonaLikeCount)

      if(Characters.allCharacters.length > 0) {
        const characterIndex = Characters.allCharacters.findIndex((char) => char.characterId ? char.characterId === (currentCharacter.characterId ? currentCharacter.characterId : currentCharacter.userPersonaId) : char.userPersonaId === (currentCharacter.characterId ? currentCharacter.characterId: currentCharacter.userPersonaId))
        Characters.allCharacters[characterIndex].characterLikeCount = data.response.updatedLikeCount
      }

      const index = LoginCreds.userLikes.findIndex((item) => item === currentCharacter.characterId ? currentCharacter.characterId : currentCharacter.userPersonaId)
      LoginCreds.userLikes.splice(index, 1)
    })
  }

  const HandleGetNews = () => {
    // if language in or out is not selected, return
    if (languageIn === '' || languageOut === '') {
      alert('Please select a language in and out')
      return
    }
    setLoading('Getting News . . .')
    fetch(`${apiURL}/getDailyNews?tgtLang=${languageOut}&index=${newsIndex}&voiceId=${voiceId}`)
    .then((res) => res.json())
    .then((data) => {
      if(data.response.error) {
        if(data.response.error === 'No More Interactions Left For The Month')
        setNoMoreInteractions(true)
        setLoading(data.response.error)
      } else {
        setLoading('')
        // put newsclipping.translatedNewsClipping and newsclipping.audioChunk into an array
        let newsClipping = data.response.newsClipping
        let newsMessages = []
        newsMessages.push({characterId: 1, message: newsClipping.translatedNewsClipping, audioChunk: newsClipping.audioChunk})
        handleAddMessage(newsMessages, 0)
      }
    })
    setNewsIndex(newsIndex+1)
  }

  const handleSetCharacter = (characterObj) => {
    const updatedCharacters = characters.map((currentCharacterObj) => {
      if (currentCharacterObj.character.characterId === currentCharacter.characterId) {
        return {
          ...currentCharacterObj,
          messages: messages,
        }
      } else {
        return currentCharacterObj
      }
    })

    setSessionToken(characterObj.sessionToken)
    setVoiceID(characterObj.voiceId)
    setMessages(characterObj.messages)
    setCharacters(updatedCharacters)
    setCurrentCharacter(characterObj.character)
    setLanguageIn(characterObj.defaultLang ? characterObj.defaultLang : '')
    setLangCode(characterObj.defaultLang ? Languages[characterObj.defaultLang] : '')
    setLanguageOut(characterObj.defaultLang ? characterObj.defaultLang : '')
    setShowLanguageSelect(characterObj.defaultLang ? false : true)
  }

  return(
    <>
    {maxSessionsReached ? (
      <div className='characterPageContainer'>
        <p className="fetchingCharacterText">Maximum Sessions Reached. Please Try Again Later.</p>
      </div>
    ) : (
      <div className='characterPageContainer'>
        {!loadingCharacter ? (
          <div className='characterPageChatContainer'>
            <div className='backButtonDiv'>
              <img className='backButton' style={{display: "none"}} src={backButton} alt='backButton' onClick={() => closeSession()}/>
            </div>
            <div className='characterPageChatHeader'>
              {characters.length > 0 && (
                <div className='characterSelectionDiv'>
                  {characters.map((characterObj) => {
                    return (
                      <div className={currentCharacter.characterId === characterObj.character.characterId ? 'characterSelectionObjSelected' : 'characterSelectionObjNotSelected'} onClick={() => handleSetCharacter(characterObj)}>
                        <img className='characterSelectionImage' src={characterObj.character.characterImage} alt='characterImage' />
                        <p className='characterSelectionText'>{characterObj.character.characterName}</p>
                      </div>
                    )
                  })}
                </div>
              )}
              <img id="characterImage" className='characterImage' src={currentCharacter.characterImage ? currentCharacter.characterImage : currentCharacter.userPersonaImage} alt='characterImage'/>
              <button id="characterButton" onClick={setCharacterModalIsOpen}>{currentCharacter.characterName ? currentCharacter.characterName : currentCharacter.userPersonaName}</button>
              <p className='characterHeaderDesc' style={{display:'none'}}>{currentCharacter.characterDesc ? currentCharacter.characterDesc : currentCharacter.userPersonaDesc}</p>
              <div className='characterLikeCount' style={{display:'none'}}>
                <p><b>{formatNumber(currentCharacterLikeCount)}</b></p>
                {LoginCreds.userLikes.includes(currentCharacter.characterId ? currentCharacter.characterId : currentCharacter.userPersonaId) ? (
                  <img onClick={() => {handleRemoveLike()}} src={likedIcon} alt='likedIcon'/>
                ) : (
                  <img onClick={() => {LoginCreds.devToken !== '' ? handleSendLike() : setSignInToLike(true)}} src={notLikedIcon} alt='likedIcon'/>
                )}
              </div>
              <div id='translateDemoDiv'>
                <div id='translateTop'>
                  <button id='translateButton' className='translateButtons' onClick={() => handleMessageTypeChange('translate')} style={{fontSize: "small"}}>Translate</button>
                  <button id='chatButton' className='translateButtons' onClick={() => handleMessageTypeChange('chat')} style={{fontSize: "small", width: "5rem", backgroundColor: "blue"}}>Chat</button>
                  <button className='translateButtons' style={{fontSize: "small", width: "5rem"}} onClick={() => HandleGetNews()}>News</button>
                </div>
              </div>
              {(currentCharacter.devToken !== undefined && currentCharacter.devToken === LoginCreds.devToken) &&
                <button onClick={() => closeSessionAndEditCharacter()}>Edit Character</button>
              }
            </div>
            <div className='characterPageBox'>
              <div id='chat_box' className='characterPageChatBox'>
              {messages.length > 0 &&
                messages.map((message, index) => (
                  message.characterId !== 0 ? (
                    <div key={index} className='characterMessage'>
                      <div className='messageImage'>
                        <img src={currentCharacter.characterImage ? currentCharacter.characterImage : currentCharacter.userPersonaImage} alt='avatarImg' />
                        <p>{currentCharacter.characterName ? currentCharacter.characterName : currentCharacter.userPersonaName}</p>
                      </div>
                      <p>{message.message}</p>
                    </div>
                  ) : (
                    <div key={index} className='userMessage'>
                      <p>{message.message}</p>
                      <div className='messageImage'>
                        <img src={LoginCreds.profileImage !== '' ? LoginCreds.profileImage : userPfp} alt='avatarImg' />
                        <p>{LoginCreds.userName !== '' ? LoginCreds.userName : 'Guest'}</p>
                      </div>
                    </div>
                  )
                ))
              }
            </div>
            <audio ref={audioRef} />
            {error !== '' && (
              <p style={{fontSize:'20px', color: 'red', margin: '0'}}>{error}</p>
            )}
            {loading !== '' && (
              <p style={{fontSize:'20px', margin: '0'}}>{loading}</p>
            )}
            <div className='characterPageInputBox'>
            {/* Selections for language in */}
            {showLanguageSelect && (
              <div id='languageSelectDiv'>
              <div id='inOutDiv'>
                
                <select id='languageInSelect' className='languageSelect' value={languageIn} onChange={handleLanguageInChange}>
                <option value="" disabled selected>Select input language</option>
                {Object.keys(Languages).map((language) => {
                  return(
                    <option id='optionBar' key={language} value={language}>{language + ' / ' + LanguageToChinese[language]}</option>
                  )
                })}
                </select>

                {/* Selections for language out */}
                
                <select id='languageOutSelect' className='languageSelect' value={languageOut} onChange={handleLanguageOutChange}>
                <option value="" disabled selected>Select output language</option>
                  {Object.keys(Languages).map((language) => {
                    return(
                      <option key={language} value={language}>{language + ' / ' + LanguageToChinese[language]}</option>
                    )
                  })}
                </select>            
              </div>
            </div>
            )}
              <input
                type='text'
                id='messageInput'
                value={userMessage}
                onKeyDown={(e) => handleKeyEvent(e.key)}
                onChange={(e) => setUserMessage(e.target.value)}
                placeholder='Enter message...'
              />
              <div className='characterPageActions'>
                <button disabled={userMessage === ''} className={userMessage === '' ? 'disabledButton' : 'submitButton'} onClick={handleSendMessage}>Send</button>

                <button
                  className='nonSelectedButton'
                  onClick={() => {
                    isRecording ? stopRecording() : startRecording()
                  }}
                  >
                  {isRecording ? 'Stop Mic' : 'Start Mic'}
                </button>
              </div>
            </div>
          </div>
        </div>
        ) : (
          <div>
            {error !== '' ? (
              <p style={{ fontSize: '20px', color: 'red', margin: '0' }}>{error}</p>
            ) : (
              <p className="fetchingCharacterText">Fetching Character . . .</p>
            )}
          </div>
        )}
      </div>
    )}
      <CharacterModal
        currentCharacter={currentCharacter}
        characterModalIsOpen={characterModalIsOpen}
        handleCloseModal={handleCloseModal}
        handleReport={handleReport}
      ></CharacterModal>
      <NoMoreInteractions
        showPopUp={noMoreInteractions}
        setShowPopUp={setNoMoreInteractions}
      />
      <RegisterForAnAccount
        showPopUp={registerForAnAccount}
        setShowPopUp={setRegisterForAnAccount}
      />
      <SignInToLike
        showPopUp={signInToLike}
        setShowPopUp={setSignInToLike}
      />
      <ConfirmReport
        showPopUp={showReport}
        setShowPopUp={setShowReport}
        character={currentCharacter}
      />
    </>
  )
}

export default TranslateDemo
