import React, { useState } from 'react'
import '../css/PopUpWindows.css'
import '../css/PopUpWindows-Mobile.css'
import { apiURL } from './URLs'
import { LoginCreds } from './loginCreds'
import { Characters } from './Characters'
import { useNavigate } from 'react-router-dom'

export function ConfirmDeleteCustomizedCharacter({showDelete, characterId, characterName, setShowDelete, setCustomizedCharacters, setSortedCharacters}) {
  const [error, setError] = useState('')

  const handleDeleteCharacter = () => {
    fetch(`${apiURL}/deleteCustomizedCharacter?devToken=${LoginCreds.devToken}&characterId=${characterId}`, {
      method: 'DELETE'
    })
    .then((res) => res.json())
    .then((data) => {
      if(data.response.error) {
        setError(error)
        setTimeout(() => {
          setError('')
        }, 2000)
      } else {
        const index = Characters.customizedCharacters.findIndex((char) => char.characterId === characterId)
        if(index !== -1) {
          const character = Characters.customizedCharacters[index]
          if(character.characterIsPublic) {
            const index = Characters.sortedCharacters.findIndex((_character) => _character.characterId === character.characterId)
            Characters.sortedCharacters.splice(index, 1)
            if(setSortedCharacters)
              setSortedCharacters([...Characters.sortedCharacters])
          }

          Characters.customizedCharacters.splice(index, 1)
          if(setCustomizedCharacters)
            setCustomizedCharacters([...Characters.customizedCharacters])
        }
      }
    })
  }

  return (
    <>
      {showDelete && (
        <div className="modal-container">
        <div className="blur-background"></div>
        <div className="modal-content">
          <p>Are you sure you want to delete {characterName}?</p>
          {error !== '' && (
            <p>{error}</p>
          )}
          <div className='modal-content-buttons'>
            <button onClick={() => handleDeleteCharacter()}>Delete</button>
            <button onClick={() => setShowDelete(false)}>Cancel</button>
          </div>
        </div>
      </div>
      )}
    </>
  )
}

export function ConfirmDeleteUserPersona({showDelete, userPersona, setShowDelete, setUserPersona}) {
  const [error, setError] = useState('')

  const handleDeletePersona = () => {
    fetch(`${apiURL}/deleteUserPersona?devToken=${LoginCreds.devToken}&userPersonaId=${userPersona.userPersonaId}`, {
      method: 'DELETE'
    })
    .then((res) => res.json())
    .then((data) => {
      if(data.response.error) {
        setError(error)
        setTimeout(() => {
          setError('')
        }, 2000)
      } else {
        const index = Characters.userPersonas.findIndex((char) => char.userPersonaId === userPersona.userPersonaId)
        if(index !== -1)
          Characters.userPersonas.splice(index, 1)
        setUserPersona(null)
      }
    })
  }

  return (
    <>
      {showDelete && (
        <div className="modal-container">
        <div className="blur-background"></div>
        <div className="modal-content">
          <p>Are you sure you want to delete {userPersona.userPersonaName}?</p>
          {error !== '' && (
            <p>{error}</p>
          )}
          <div className='modal-content-buttons'>
            <button onClick={() => handleDeletePersona()}>Delete</button>
            <button onClick={() => setShowDelete(false)}>Cancel</button>
          </div>
        </div>
      </div>
      )}
    </>
  )
}

export function NoMoreInteractions({showPopUp, setShowPopUp}) {
  const navigate = useNavigate()

  const handleRemovePopUp = () => {
    setShowPopUp(false)
    navigate('/')
  }

  return (
    <>
      {showPopUp && (
        <div className="modal-container">
        <div className="blur-background" onClick={() => handleRemovePopUp()}></div>
        <div className="modal-content">
          <p>You have no more interactions left for the month.</p>
          <div className='modal-content-buttons'>
            <button onClick={() => handleRemovePopUp()}>Ok</button>
          </div>
        </div>
      </div>
      )}
    </>
  )
}

export function RegisterForAnAccount({showPopUp, setShowPopUp}) {
  const navigate = useNavigate()

  const handleRemovePopUp = (option) => {
    setShowPopUp(false)
    if(option === 0)
      navigate('/signUp')
    else
      navigate('/login')
  }

  return (
    <>
      {showPopUp && (
        <div className="modal-container">
        <div className="blur-background"></div>
        <div className="modal-content">
          <p>Register for an account or sign in to keep interacting with characters!</p>
          <div className='modal-content-buttons'>
            <button onClick={() => handleRemovePopUp(0)}>Register</button>
            <button onClick={() => handleRemovePopUp(1)}>Sign In</button>
          </div>
        </div>
      </div>
      )}
    </>
  )
}

export function SignInToLike({showPopUp, setShowPopUp}) {
  const handleRemovePopUp = () => {
    setShowPopUp(false)
  }

  return (
    <>
      {showPopUp && (
        <div className="modal-container">
        <div className="blur-background" onClick={() => handleRemovePopUp()}></div>
        <div className="modal-content">
          <p>You must be signed in to like characters!</p>
          <div className='modal-content-buttons'>
            <button onClick={() => handleRemovePopUp()}>Ok!</button>
          </div>
        </div>
      </div>
      )}
    </>
  )
}

export function SignInToFavorite({showPopUp, setShowPopUp}) {
  const handleRemovePopUp = () => {
    setShowPopUp(false)
  }

  return (
    <>
      {showPopUp && (
        <div className="modal-container">
        <div className="blur-background" onClick={() => handleRemovePopUp()}></div>
        <div className="modal-content">
          <p>You must be signed in to favorite characters!</p>
          <div className='modal-content-buttons'>
            <button onClick={() => handleRemovePopUp()}>Ok!</button>
          </div>
        </div>
      </div>
      )}
    </>
  )
}

export function SignInToReport({showPopUp, setShowPopUp}) {
  const handleRemovePopUp = () => {
    setShowPopUp(false)
  }

  return (
    <>
      {showPopUp && (
        <div className="modal-container">
        <div className="blur-background" onClick={() => handleRemovePopUp()}></div>
        <div className="modal-content">
          <p>You must be signed in to report characters!</p>
          <div className='modal-content-buttons'>
            <button onClick={() => handleRemovePopUp()}>Ok!</button>
          </div>
        </div>
      </div>
      )}
    </>
  )
}

export function ConfirmReport({showPopUp, setShowPopUp, character, setSortedCharacters, setCategories, setUserPersonas}) {
  const [reason, setReason] = useState('')
  const [error, setError] = useState(false)

  const handleRemovePopUp = () => {
    setShowPopUp(false)
  }

  const handleReportCharacter = () => {
    if(reason !== '') {
      const reportInfo = {
        devToken: LoginCreds.devToken,
        characterId: character.characterId ? character.characterId : character.userPersonaId,
        reason: reason
      }
      const formData = new FormData()
      formData.append('reportInfo', JSON.stringify(reportInfo))
      fetch(`${apiURL}/reportCharacter`, {
        method: 'POST',
        body: formData
      })
      .then((res) => res.json())
      .then(() => {
        setShowPopUp(false)
        if(character.characterId) {
          const allCharactersIndex = Characters.allCharacters.findIndex((char) => char.characterId === character.characterId)
          if(allCharactersIndex !== -1)
            Characters.allCharacters.splice(allCharactersIndex, 1)

          Characters.categoryCharacters.forEach((category) => {
            category.characters = category.characters.filter((char) => {
              return char.characterId !== character.characterId
            })
          })

          if(setSortedCharacters && setCategories) {
            setSortedCharacters([...Characters.sortedCharacters])
            setCategories([...Characters.categoryCharacters])
          }
        } else {
          const index = Characters.userPersonas.findIndex((char) => char.userPersonaId === character.userPersonaId)
          Characters.userPersonas.splice(index, 1)

          if(setUserPersonas)
            setUserPersonas([...Characters.userPersonas])
        }
      })
    } else {
      setError(true)
      setTimeout(() => {
        setError(false)
      }, 2000)
    }
  }

  return (
    <>
      {showPopUp && (
        <div className="modal-container">
        <div className="blur-background" onClick={() => handleRemovePopUp()}></div>
        <div className="modal-report-content">
          <p>Reason for reporting character {character.characterName ? character.characterName : character.userPersonaName}?</p>
          <textarea
            className='reportText'
            value={reason}
            maxLength={200}
            placeholder='reason'
            onChange={(e) => setReason(e.target.value)}
          />
          <div className='modal-content-buttons'>
            <button onClick={() => handleReportCharacter()}>Report</button>
            <button onClick={() => handleRemovePopUp()}>Cancel</button>
          </div>
          {error && (
            <p>Must Provide Reason.</p>
          )}
        </div>
      </div>
      )}
    </>
  )
}

export function ErrorDialogWindow({showErrorDialog, setShowErrorDialog, errorDialogText}) {

  const handleCloseErrorDialog = () => {
    setShowErrorDialog(false)
  }

  return (
    <>
      {showErrorDialog && (
        <div className="modal-container">
          <div className="blur-background" onClick={() => handleCloseErrorDialog()}></div>
          <div className="error-modal-content">
            <p>{errorDialogText}</p>
            <div className='modal-content-buttons'>
              <button onClick={() => handleCloseErrorDialog()}>OK</button>
            </div>
          </div>
      </div>
      )}
    </>
  )
}
