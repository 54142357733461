import { useState } from 'react'
import { LoginCreds } from '../utilities/loginCreds'
import { useNavigate } from 'react-router-dom'
import { Characters } from '../utilities/Characters'
import { apiURL } from '../utilities/URLs'
import { SetLoginCreds } from '../utilities/Extensions'
import { GoogleLogin } from '@react-oauth/google'
import { jwtDecode } from 'jwt-decode'

import '../css/Login.css'
import '../css/Login-Mobile.css'

function Login() {
  const navigate = useNavigate()
  const [emailOrUsername, setEmailOrUsername] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')

  const checkLogin = () => {
    if(emailOrUsername !== '' && password !== '') {
      fetch(`${apiURL}/login?isGoogleAccount=0&emailOrUserName=${emailOrUsername}&password=${password}`)
      .then((res) => res.json())
      .then((data) => {
        if(data.response.error)
          setError(data.response.error)
        else
          setLoginCreds(data.response.account)
      })
    } else {
      setError('Missing Fields')
    }
  }

  const setLoginCreds = (account) => {
    SetLoginCreds(account)
    localStorage.setItem('devToken', LoginCreds.devToken)

    fetch(`${apiURL}/getCustomizedCharacters?devToken=${LoginCreds.devToken}`)
    .then((res) => res.json())
    .then((data) => {
      Characters.customizedCharacters = data.response.customizedCharacters
      fetch(`${apiURL}/getUserLikes?devToken=${LoginCreds.devToken}`)
      .then((res) => res.json())
      .then((data) => {
        LoginCreds.userLikes = data.response.userLikes
        fetch(`${apiURL}/getUserFavorites?devToken=${LoginCreds.devToken}`)
        .then((res) => res.json())
        .then((data) => {
          LoginCreds.userFavorites = data.response.userFavorites
          navigate('/')
        })
      })
    })
  }

  const getGoogleUser = async (token) => {
    const googleUser = jwtDecode(token)

    fetch(`${apiURL}/login?isGoogleAccount=1&emailOrUserName=${googleUser.email}`)
    .then((res) => res.json())
    .then((data) => {
      if(data.response.error)
        setError(data.response.error)
      else
        setLoginCreds(data.response.account)
    })
  }

  return(
    <div className='loginPage'>
      <div className='loginContainer'>
        <div className='loginHeader'>
          <h1>chatube.ai</h1>
          <p>Login</p>
        </div>
          <div className='loginFields'>
            <div className='inputField'>
              <p>Email Or Username:</p>
              <input
                type='text'
                value={emailOrUsername}
                id='emailOrUsernameInput'
                onChange={(e) => setEmailOrUsername(e.target.value)}
                placeholder='Email Or Username'
              />
            </div>
            <div className='inputField'>
              <p>Password:</p>
              <input
                type='password'
                value={password}
                id='passwordInput'
                onChange={(e) => setPassword(e.target.value)}
                placeholder='Password'
              />
            </div>
            <button onClick={() => checkLogin()}>Login</button>
            {error !== '' &&
              <p className='error'>{error}</p>
            }
            <div className='separator'>OR</div>
            <GoogleLogin onSuccess={credentials => (getGoogleUser(credentials.credential))} />
          </div>
      </div>
    </div>
  )
}

export default Login