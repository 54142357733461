export var LoginCreds = {
  isGoogleAccount: 0,
  email: '',
  userName: '',
  password: '',
  profileImage: '', 
  devToken: '',
  userPersonaId: null,
  userInteractions: 0,
  userLikes: [],
  userFavorites: [],
  subTier: 0
}