// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 480px) {

  * {
    box-sizing: border-box;
  }

  footer {
    display: flex;
    justify-content: center;
    order: 2;
    background-color: rgb(20, 20, 20);
    width: 100%;
    height: 15rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .footerContent {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .content h3 {
    font-family: 'Roboto', sans-serif;
    font-weight: normal;
    font-size: medium;
    margin: 0;
  }

  .links {
    display: flex;
    /* gap: 20px; */
    flex-direction: column;
  }

  .links p {
    font-family: 'Roboto', sans-serif;
    font-weight: lighter;
    font-size: small;
    color: rgb(94, 94, 255);
    text-decoration: underline;
  }

  .links p:hover {
    cursor: pointer;
    color: rgb(61, 61, 255);
  }
}`, "",{"version":3,"sources":["webpack://./src/css/Footer-Mobile.css"],"names":[],"mappings":"AAAA;;EAEE;IACE,sBAAsB;EACxB;;EAEA;IACE,aAAa;IACb,uBAAuB;IACvB,QAAQ;IACR,iCAAiC;IACjC,WAAW;IACX,aAAa;IACb,iBAAiB;IACjB,oBAAoB;EACtB;;EAEA;IACE,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,WAAW;IACX,YAAY;EACd;;EAEA;IACE,iCAAiC;IACjC,mBAAmB;IACnB,iBAAiB;IACjB,SAAS;EACX;;EAEA;IACE,aAAa;IACb,eAAe;IACf,sBAAsB;EACxB;;EAEA;IACE,iCAAiC;IACjC,oBAAoB;IACpB,gBAAgB;IAChB,uBAAuB;IACvB,0BAA0B;EAC5B;;EAEA;IACE,eAAe;IACf,uBAAuB;EACzB;AACF","sourcesContent":["@media (max-width: 480px) {\n\n  * {\n    box-sizing: border-box;\n  }\n\n  footer {\n    display: flex;\n    justify-content: center;\n    order: 2;\n    background-color: rgb(20, 20, 20);\n    width: 100%;\n    height: 15rem;\n    padding-top: 2rem;\n    padding-bottom: 2rem;\n  }\n\n  .footerContent {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    width: 100%;\n    height: 100%;\n    padding-left: 1rem;\n    padding-right: 1rem;\n  }\n\n  .content {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    width: 100%;\n    height: 100%;\n  }\n\n  .content h3 {\n    font-family: 'Roboto', sans-serif;\n    font-weight: normal;\n    font-size: medium;\n    margin: 0;\n  }\n\n  .links {\n    display: flex;\n    /* gap: 20px; */\n    flex-direction: column;\n  }\n\n  .links p {\n    font-family: 'Roboto', sans-serif;\n    font-weight: lighter;\n    font-size: small;\n    color: rgb(94, 94, 255);\n    text-decoration: underline;\n  }\n\n  .links p:hover {\n    cursor: pointer;\n    color: rgb(61, 61, 255);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
