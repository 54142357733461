import { React } from 'react'
import { useEffect, useState, useRef } from 'react'
import { Characters } from '../utilities/Characters'
import { ToastContainer } from 'react-toastify'
import { CharacterTile, MyCharacter } from '../utilities/CharacterTiles'
import ChatubeHeader from './ChatubeHeader'
import LeftSideBar from './LeftSideBar'
import Footer from './Footer'

import 'react-toastify/dist/ReactToastify.css'
import '../css/Home.css'
import '../css/Home-Mobile.css'

import arrowIcon from '../assets/icons/arrow.png'
import categoryArrow from '../assets/icons/categoryArrow.png'

function Home() {
  const [customizedCharacters, setCustomizedCharacters] = useState([])
  const [sortedCharacters, setSortedCharacters] = useState([])
  const [categoryCharacters, setCategories] = useState([])
  const [selectedButton, setSelectedButton] = useState(-1)
  const [loading, setLoading] = useState(true)
  const [refreshHome, setRefreshHome] = useState(false)
  const [charactersPerPage, setCharactersPerPage] = useState(18)
  const [currentPage, setCurrentPage] = useState(() => {
    return parseInt(localStorage.getItem('currentHomePage')) || 1
  })
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  
  useEffect(() => {
    setLoading(true)

    setCustomizedCharacters(Characters.customizedCharacters)
    setSortedCharacters(Characters.sortedCharacters)
    setCategories(Characters.characterCategoryButtons)

    setTimeout(() => {
      setLoading(false)
    }, 100)
  }, [])

  useEffect(() => {
    setSelectedButton(-1)
    setRefreshHome(false)
    setCurrentPage(1)
  }, [refreshHome])

  useEffect(() => {
    window.addEventListener('resize', setWindowWidth(window.innerWidth))

    return () => {
      window.removeEventListener('resize', setWindowWidth(window.innerWidth))
    }
  }, [])

  useEffect(() => {
    const charactersPerRow = Math.floor(windowWidth / 255)
    let charactersPerPageCalculated = charactersPerRow * 2
    if (charactersPerPageCalculated < 6)
      charactersPerPageCalculated = 6

    setCharactersPerPage(charactersPerPageCalculated)
  }, [windowWidth])

  useEffect(() => {
    localStorage.setItem('currentHomePage', currentPage)
  }, [currentPage])

  function ChatubeMyCharacters() {
    return (
      <div className='characters'>
        <h3>My Characters</h3>
        {customizedCharacters.length === 0 ? (
          <p className='loadingText'>Loading...</p>
        ) : (
          <div className='character-grid'>
            {customizedCharacters.map((character) => (
              <MyCharacter
                key={character.characterId}
                character={character}
                setCustomizedCharacters={setCustomizedCharacters}
              />
            ))}
          </div>
        )}
      </div>
    )
  }

  function CategoryButton({ title, id }) {
    return (
      <button onClick={() => setSelectedButton(id)} className={selectedButton === id ? 'selectedCategoryButton' : 'categoryButton'}>{title}</button>
    )
  }

  function CategoryButtons() {
    return (
      <div className='categoryButtons'>
        {categoryCharacters.map((category, index) => (
          <CategoryButton
            key={category.categoryId}
            title={category.categoryName.charAt(0).toUpperCase() + category.categoryName.slice(1)}
            id={index}
          />
        ))}
      </div>
    )
  }

  function SelectedCategory() {
    const charactersRef = useRef(null)
    const [showLeftArrow, setShowLeftArrow] = useState(false)
    const [showRightArrow, setShowRightArrow] = useState(true)
    const characterHeader = categoryCharacters[selectedButton].categoryName+' Category'

    useEffect(() => {
      const { current } = charactersRef
      if(current) {
        current.addEventListener('scroll', updateArrowVisibility, { passive: true })

        updateArrowVisibility()
    
        return () => {
          current.removeEventListener('scroll', updateArrowVisibility)
        }
      }
    }, [])

    const updateArrowVisibility = () => {
      const { current } = charactersRef
      if(current) {
        const maxScrollLeft = current.scrollWidth - current.clientWidth

        setShowLeftArrow(current.scrollLeft > 0)
        setShowRightArrow(current.scrollLeft < maxScrollLeft)
      }
    }

    const scrollCharacters = (direction) => {
      const { current } = charactersRef
      if(current) {
        const scrollAmount = Math.floor((current.clientWidth / 205)) * 205
        const newScrollPosition = direction === 'left' ? current.scrollLeft - scrollAmount : current.scrollLeft + scrollAmount

        current.scrollTo({
          left: newScrollPosition,
          behavior: 'smooth'
        })
      }
    }

    return (
      <div id='moga-characters' className='categoryCharacters'>
        <h3>{characterHeader.charAt(0).toUpperCase() + characterHeader.slice(1)}</h3>
        <div className='categoryArrowDiv'>
          {showLeftArrow && (
            <img className='categoryLeftArrow' onClick={() => scrollCharacters('left')} src={categoryArrow} alt='categoryArrow' />
          )}
          {categoryCharacters[selectedButton].characters.length === 0 ? (
            <p className='loadingText'>Loading...</p>
          ) : (
            <div className='character-grid' ref={charactersRef}>
              {categoryCharacters[selectedButton].characters.map((characterObj) => (
                <CharacterTile
                  key={characterObj.characterId}
                  character={characterObj}
                  characterVariant={0}
                  setSortedCharacters={setSortedCharacters}
                  setCategories={setCategories}
                  selectedButton={selectedButton}
                />
              ))}
            </div>
          )}
          {showRightArrow && (
            <img className='categoryRightArrow' onClick={() => scrollCharacters('right')} src={categoryArrow} alt='categoryArrow' />
          )}
        </div>
      </div>
    )
  }

  function ChatubeCharacters() {
    const indexOfLastCharacter = currentPage * charactersPerPage
    const indexOfFirstCharacters = indexOfLastCharacter - charactersPerPage
    const currentCharacters = sortedCharacters.slice(indexOfFirstCharacters, indexOfLastCharacter)
    const totalPages = Math.ceil(sortedCharacters.length / charactersPerPage)

    return (
      <div className='characters'>
        {sortedCharacters.length === 0 ? (
          <p className='loadingText'>Loading...</p>
        ) : (
          <div className='all-character-grid-container'>
            <div className='all-character-grid'>
              {currentCharacters.map((characterObj) => (
                <CharacterTile
                  key={characterObj.characterId}
                  character={characterObj}
                  characterVariant={1}
                  setSortedCharacters={setSortedCharacters}
                  setCategories={setCategories}
                  selectedButton={selectedButton}
                />
              ))}
            </div>
            <div className='pagination'>
              <img className={`leftArrow ${currentPage === 1 ? 'disabled-arrow' : ''}`} onClick={() => setCurrentPage((prevPage) => Math.max(prevPage-1, 1))} src={arrowIcon} alt='arrowIcon'/>
              <span>{`Page ${currentPage} of ${totalPages}`}</span>
              <img className={`rightArrow ${currentPage === totalPages ? 'disabled-arrow' : ''}`} onClick={() => setCurrentPage((prevPage) => Math.min(prevPage+1, totalPages))} src={arrowIcon} alt='arrowIcon'/>
            </div>
          </div>
        )}
      </div>
    )
  }

  return(
    <div className='App'>
      <header className='App-header'>
        {!loading && (
          <>
            <ChatubeHeader />
            <LeftSideBar
              setRefreshHome={setRefreshHome}
            />
            {customizedCharacters.length !== 0 && (
              <ChatubeMyCharacters />
            )}
            {selectedButton !== -2 && (
              <>
                <CategoryButtons />
                {selectedButton !== -1 && (
                  <SelectedCategory />
                )}
              </>
            )}
            <ChatubeCharacters />
            <ToastContainer />
            <Footer />
          </>
        )}

      </header>
    </div>
  )
}

export default Home
