// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notFoundContainer {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  align-items: center;
  background-color: #282c34;
}

.notFoundContainer h1 {
  color: white;
  font-family: 'Roboto', sans-serif;
  margin-top: 10rem;
}

.notFoundContainer p {
  color: white;
  font-family: 'Roboto', sans-serif;
  text-align: center;
  padding: 2rem;
}

.notFoundContainer button {
  width: 6rem;
  height: 3rem;
  font-family: 'Roboto', sans-serif;
  font-weight: normal;
  border-radius: 10px;
  font-size: medium;
  border: 0;
  background-color: #0056b3;
  color: white;
  transform: scale(1.0);
  margin-bottom: 2rem;
}

.notFoundContainer button:hover {
  cursor: pointer;
  transform: scale(1.05);
}`, "",{"version":3,"sources":["webpack://./src/css/NotFound.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;EACE,YAAY;EACZ,iCAAiC;EACjC,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,iCAAiC;EACjC,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,iCAAiC;EACjC,mBAAmB;EACnB,mBAAmB;EACnB,iBAAiB;EACjB,SAAS;EACT,yBAAyB;EACzB,YAAY;EACZ,qBAAqB;EACrB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,sBAAsB;AACxB","sourcesContent":[".notFoundContainer {\n  display: flex;\n  flex-direction: column;\n  width: 100vw;\n  height: 100vh;\n  align-items: center;\n  background-color: #282c34;\n}\n\n.notFoundContainer h1 {\n  color: white;\n  font-family: 'Roboto', sans-serif;\n  margin-top: 10rem;\n}\n\n.notFoundContainer p {\n  color: white;\n  font-family: 'Roboto', sans-serif;\n  text-align: center;\n  padding: 2rem;\n}\n\n.notFoundContainer button {\n  width: 6rem;\n  height: 3rem;\n  font-family: 'Roboto', sans-serif;\n  font-weight: normal;\n  border-radius: 10px;\n  font-size: medium;\n  border: 0;\n  background-color: #0056b3;\n  color: white;\n  transform: scale(1.0);\n  margin-bottom: 2rem;\n}\n\n.notFoundContainer button:hover {\n  cursor: pointer;\n  transform: scale(1.05);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
