// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (min-width: 481px) {

  footer {
    display: flex;
    justify-content: center;
    background-color: rgb(20, 20, 20);
    width: 100%;
    height: 8rem;
    margin-top: auto;

  }

  .footerContent {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 100%;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  .content h3 {
    font-family: 'Roboto', sans-serif;
    font-weight: normal;
    font-size: medium;
    margin: 0;
  }

  .links {
    display: flex;
    gap: 20px;
  }

  .links p {
    font-family: 'Roboto', sans-serif;
    font-weight: lighter;
    font-size: small;
    color: rgb(94, 94, 255);
    text-decoration: underline;
  }

  .links p:hover {
    cursor: pointer;
    color: rgb(61, 61, 255);
  }
}`, "",{"version":3,"sources":["webpack://./src/css/Footer.css"],"names":[],"mappings":"AAAA;;EAEE;IACE,aAAa;IACb,uBAAuB;IACvB,iCAAiC;IACjC,WAAW;IACX,YAAY;IACZ,gBAAgB;;EAElB;;EAEA;IACE,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,UAAU;IACV,YAAY;EACd;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,WAAW;IACX,YAAY;EACd;;EAEA;IACE,iCAAiC;IACjC,mBAAmB;IACnB,iBAAiB;IACjB,SAAS;EACX;;EAEA;IACE,aAAa;IACb,SAAS;EACX;;EAEA;IACE,iCAAiC;IACjC,oBAAoB;IACpB,gBAAgB;IAChB,uBAAuB;IACvB,0BAA0B;EAC5B;;EAEA;IACE,eAAe;IACf,uBAAuB;EACzB;AACF","sourcesContent":["@media (min-width: 481px) {\n\n  footer {\n    display: flex;\n    justify-content: center;\n    background-color: rgb(20, 20, 20);\n    width: 100%;\n    height: 8rem;\n    margin-top: auto;\n\n  }\n\n  .footerContent {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    width: 50%;\n    height: 100%;\n  }\n\n  .content {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    width: 100%;\n    height: 100%;\n  }\n\n  .content h3 {\n    font-family: 'Roboto', sans-serif;\n    font-weight: normal;\n    font-size: medium;\n    margin: 0;\n  }\n\n  .links {\n    display: flex;\n    gap: 20px;\n  }\n\n  .links p {\n    font-family: 'Roboto', sans-serif;\n    font-weight: lighter;\n    font-size: small;\n    color: rgb(94, 94, 255);\n    text-decoration: underline;\n  }\n\n  .links p:hover {\n    cursor: pointer;\n    color: rgb(61, 61, 255);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
