import { useEffect } from "react"
import { currentUserInteraction, setCurrentUserInteraction } from "../utilities/Stripe"
import { LoginCreds } from "../utilities/loginCreds"
import { apiURL } from "../utilities/URLs"

import '../css/CheckoutComplete.css'
import { useNavigate } from "react-router-dom"

export default function CheckoutComplete() {
  const navigate = useNavigate()

  useEffect(() => {
    const interactionInfo = {
      devToken: LoginCreds.devToken,
      userInteractions: currentUserInteraction.userInteractions
    }
    const formData = new FormData()
    formData.append('interactionInfo', JSON.stringify(interactionInfo))

    fetch(`${apiURL}/addUserInteractions`, {
      method: 'PUT',
      body: formData
    })
    .then((res) => res.json())
    .then(() => {
      LoginCreds.userInteractions += currentUserInteraction.userInteractions
      setCurrentUserInteraction(null)
    })
  }, [])

  return (
    <div className='checkoutCompleteContainer'>
      <h1 className='checkoutCompleteHeader'>Checkout Complete!</h1>
      <p className='checkoutCompleteText'>Your Interactions Have Been Added To Your Account!</p>
      <button className='checkoutCompleteButton' onClick={() => navigate('/ProfilePage')}>Back To Profile</button>
    </div>
  )
}