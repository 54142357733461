import ReactDOM from 'react-dom/client'
import { Routes, Route, HashRouter } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { GoogleOAuthProvider } from '@react-oauth/google'

import { apiURL } from './utilities/URLs'
import { Characters } from './utilities/Characters'
import { LoginCreds } from './utilities/loginCreds'
import { SetLoginCreds } from './utilities/Extensions'
import { GoogleAuth } from './utilities/GoogleAuth'

import Home from './pages/Home'
import CharacterPage from './pages/CharacterPage'
import Login from './pages/Login'
import SignUp from './pages/SignUp'
import CategorySearch from './pages/CategorySearch'
import CreateCharacter from './pages/CreateCharacter'
import EditCustomizedCharacter from './pages/EditCustomizedCharacter'
import ProfilePage from './pages/ProfilePage'
import CreatePersona from './pages/CreatePersona'
import EditPersona from './pages/EditPersona'
import Community from './pages/Community'
import TranslateDemo from './pages/Demos/TranslateDemo'
import NotFound from './pages/NotFound'
import TranslateRPMDemo from './pages/Demos/TranslateRPMDemo'
import Checkout from './pages/Checkout'
import FarmersMarketDemo from './pages/Demos/FarmersMarketDemo'
import CheckoutComplete from './pages/CheckoutComplete'

export default function App() {
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    const devToken = localStorage.getItem('devToken')

    if(devToken !== null && LoginCreds.devToken === '') {
      fetch(`${apiURL}/loginWithDevToken?devToken=${devToken}`)
      .then((res) => res.json())
      .then((data) => {
        SetLoginCreds(data.response.account)
        fetch(`${apiURL}/getUserLikes?devToken=${devToken}`)
        .then((res) => res.json())
        .then((data) => { 
          LoginCreds.userLikes = data.response.userLikes
          fetch(`${apiURL}/getUserFavorites?devToken=${devToken}`)
          .then((res) => res.json())
          .then((data) => { 
            LoginCreds.userFavorites = data.response.userFavorites

            fetch(`${apiURL}/getCustomizedCharacters?devToken=${LoginCreds.devToken}`)
            .then((res) => res.json())
            .then((data) => {
              if(data.response.customizedCharacters)
                Characters.customizedCharacters = data.response.customizedCharacters
            })
          })
        })
      })
    }

    fetch(`${apiURL}/getGoogleAuth`)
    .then((res) => res.json())
    .then((data) => {
      GoogleAuth.clientId = data.response.clientId

      fetch(`${apiURL}/getAllCategories`)
      .then((res) => res.json())
      .then((data) => { 
        Characters.categories = data.response.categories.sort((a, b) => {
          return a.categoryRank - b.categoryRank
        })

        var categoryButtons = []
        Characters.categories.forEach((category) => {
          const categoryObject = {categoryId: category.categoryId, categoryName: category.categoryName, categoryRank: category.categoryRank, characters: []}
          categoryButtons.push(categoryObject)
        })

        Characters.characterCategoryButtons = categoryButtons

        fetch(`${apiURL}/getAllTags`)
        .then((res) => res.json())
        .then((data) => { 
          Characters.tags = data.response.tags

          fetch(`${apiURL}/getAllCharacters`)
          .then((res) => res.json())
          .then((data) => { 
            sortCharacters(data.response.allCharacters.baseCharacters.concat(data.response.allCharacters.publicCustomizedCharacters))

            var categoryButtons = []
            Characters.categories.forEach((category) => {
              const categoryObject = {categoryId: category.categoryId, categoryName: category.categoryName, categoryRank: category.categoryRank, userPersonas: []}
              categoryButtons.push(categoryObject)
            })

            Characters.userPersonaCategoryButtons = categoryButtons
            
            fetch(`${apiURL}/getPublicUserPersonas`)
            .then((res) => res.json())
            .then((data) => {
              sortUserPersonas(data.response.publicUserPersonas)
              sortUserPersonaCategoryCharacters(Characters.userPersonas)
              
              setIsLoaded(true)
            })
          })
        })
      })
    })
  }, [])

  const sortUserPersonas = (userPersonas) => {
    const sortedPersonas = userPersonas.sort((a, b) => {
      if (b.userPersonaLikeCount !== a.userPersonaLikeCount)
        return b.userPersonaLikeCount - a.userPersonaLikeCount
      return new Date(a.userPersonaCreatedDate) - new Date(b.userPersonaCreatedDate)
    })

    Characters.userPersonas = sortedPersonas
  }

  const sortCharacters = (characters) => {
    Characters.allCharacters = characters
    sortAllCharacters()
    sortCategoryCharacters(characters)
  }

  const sortAllCharacters = () => {
    const sortedCharacters = Characters.allCharacters.sort((a, b) => {
      if (b.characterLikeCount !== a.characterLikeCount)
        return b.characterLikeCount - a.characterLikeCount
      return new Date(a.characterCreatedDate) - new Date(b.characterCreatedDate)
    })

    Characters.sortedCharacters = sortedCharacters
  }

  const sortCategoryCharacters = (characters) => {
    characters.forEach((character) => {
      Characters.characterCategoryButtons.forEach((category) => {
        character.categoryIds.forEach((categoryId) => {
          if(categoryId === category.categoryId)
            category.characters.push(character)
        })
      })
    })

    Characters.characterCategoryButtons.forEach((category) => {
      category.characters.sort((a, b) => b.characterLikeCount - a.characterLikeCount)
    })
  }

  const sortUserPersonaCategoryCharacters = (userPersonas) => {
    userPersonas.forEach((userPersona) => {
      Characters.userPersonaCategoryButtons.forEach((category) => {
        userPersona.categoryIds.forEach((categoryId) => {
          if(categoryId === category.categoryId)
            category.userPersonas.push(userPersona)
        })
      })
    })

    Characters.userPersonaCategoryButtons.forEach((category) => {
      category.userPersonas.sort((a, b) => b.userPersonaLikeCount - a.userPersonaLikeCount)
    })
  }

  return (
    <>
    {isLoaded ? (
      <GoogleOAuthProvider clientId={GoogleAuth.clientId}>
        <HashRouter>
          <Routes>
            <Route path='/' element={<Home />}/>
            <Route path='/login' element={<Login />}/>
            <Route path='/signUp' element={<SignUp />}/>
            <Route path='/CharacterPage/:characterId' element={<CharacterPage />}/>
            <Route path='/CharacterPage/' element={<CharacterPage />}/>
            <Route path='/CategorySearch' element={<CategorySearch />}/>
            <Route path='/CreateCharacter' element={<CreateCharacter />}/>
            <Route path='/EditCharacter' element={<EditCustomizedCharacter />}/>
            <Route path='/ProfilePage' element={<ProfilePage />}/>
            <Route path='/CreatePersona' element={<CreatePersona />}/>
            <Route path='/EditPersona' element={<EditPersona />}/>
            <Route path='/Community' element={<Community />}/>
            <Route path='/TranslateDemo/:characterId' element={<TranslateDemo />}/>
            <Route path='/TranslateDemo' element={<TranslateDemo />}/>
            <Route path='/TranslateRPMDemo' element={<TranslateRPMDemo />}/>
            <Route path='/Checkout' element={<Checkout />}/>
            <Route path='/CheckoutComplete' element={<CheckoutComplete />}/>
            <Route path='/FarmersMarketDemo' element={<FarmersMarketDemo />}/>
            <Route path ='*' element={<NotFound />}/>
          </Routes>
        </HashRouter>
      </GoogleOAuthProvider>
    ) : (
      <div style={{ height: '100vh', backgroundColor: '#282c34' }}/>
    )}
    </>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(<App />)
