import { loadStripe } from '@stripe/stripe-js'

export const SubscriptionTiers = [
  {
    subTier: 0,
    subAmount: '0.00'
  },
  {
    subTier: 1,
    subAmount: '4.99'
  },
  {
    subTier: 2,
    subAmount: '9.99'
  }
]

export const UserInteractions = [
  {
    userInteractions: 500,
    amount: 50
  },
  {
    userInteractions: 1000,
    amount: 799
  },
  {
    userInteractions: 1500,
    amount: 1199
  }
]

export let currentUserInteraction = null

export function setCurrentUserInteraction(userInteraction) {
  currentUserInteraction = userInteraction
}
//'pk_live_51OkCVGKPG6OigVN8mnEJ7hAe5rldX4zdWiidp1GERVchsTB4q71nXyH5dYClQSIdomF5KyPB1OHPbhFYp1tzRJEO00F2TkaWiZ'
export const stripePromise = loadStripe('pk_test_51OkCVGKPG6OigVN8gaeEDVduHbifdd61tPfyA7b1R2csK9oKepLzv22kMDhHCAvDpEgWdIfG7pNXuJ137Kh2a39e00u24ZVdbl')