import { useNavigate } from 'react-router-dom'
import { LoginCreds } from '../utilities/loginCreds'
import { aiWorldURL, chatcitiesFlirting } from '../utilities/URLs'

import homeIcon from '../assets/icons/homeIcon.png'
import createIcon from '../assets/icons/createIcon.png'
import communityIcon from '../assets/icons/communityIcon.png'
import chatCitiesLiveIcon from '../assets/icons/chatCitiesLiveIcon.png'
import chatCitiesFlirting from '../assets/icons/hearticoncc.png'

function LeftSideBar({ setRefreshHome, setRefreshCommunity }) {
  const navigate = useNavigate()

  const handleNavigate = (index) => {
    switch(index) {
      case 0:
        if(setRefreshHome)
          setRefreshHome(true)
        localStorage.removeItem('currentHomePage')
        navigate('/')
        break
      case 1:
        if(LoginCreds.devToken === '')
          navigate('/Login')
        else
          navigate('/CreateCharacter')
        break
      case 2:
        if(setRefreshCommunity)
          setRefreshCommunity(true)
        localStorage.removeItem('currentCommunityPage')
        navigate('/Community')
        break
      case 3:
        window.open(aiWorldURL, '_blank')
        break
      case 4:
        window.open(chatcitiesFlirting, '_blank')
        break
      default:
        navigate('/')
        break
    }
  }

  function LeftSideIcon({ icon, name, index }) {
    return (
      <div onClick={() => handleNavigate(index)} className={index < 3 ? 'icon' : 'chatubeIcon'}>
        <img src={icon} alt='icon' />
        <p>{name}</p>
      </div>
    )
  }

  return (
    <div className='leftSideBar'>
      <LeftSideIcon
        icon={homeIcon}
        name={'Home'}
        index={0}
      />
      <LeftSideIcon
        icon={createIcon}
        name={'Create'}
        index={1}
      />
      <LeftSideIcon
        icon={communityIcon}
        name={'Personas'}
        index={2}
      />
      <LeftSideIcon
        icon={chatCitiesLiveIcon}
        name={''}
        index={3}
      />
      <LeftSideIcon
        icon={chatCitiesFlirting}
        name={''}
        index={4}
      />
    </div>
  )
}

export default LeftSideBar