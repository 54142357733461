import { useNavigate } from "react-router-dom"
import { apiURL } from "../utilities/URLs"
import { LoginCreds } from "../utilities/loginCreds"
import { PaymentElement, Elements, useStripe, useElements } from '@stripe/react-stripe-js'
import { currentUserInteraction, stripePromise } from "../utilities/Stripe"
import { useState } from "react"

import '../css/Checkout.css'
import '../css/Checkout-Mobile.css'

import backButton from '../assets/icons/backButton.png'

function Checkout() {
  const navigate = useNavigate()

  const CheckoutForm = () => {
    const stripe = useStripe()
    const elements = useElements()

    const [errorMessage, setErrorMessage] = useState(null)

    const handleSubmit = async (event) => {
      event.preventDefault()

      setErrorMessage('Sending Payment...')

      if(elements == null)
        return

      const {error: submitError} = await elements.submit()
      if(submitError) {
        setErrorMessage(submitError.message)
        return
      }

      const paymentInfo = {
        paymentAmount: currentUserInteraction.amount
      }

      const formData = new FormData()
      formData.append('paymentInfo', JSON.stringify(paymentInfo))

      const res = await fetch(`${apiURL}/createPayment`, {
        method: 'POST',
        body: formData
      })

      const data = await res.json()

      const clientSecret = data.response.clientSecret

      const {error} = await stripe.confirmPayment({
        elements,
        clientSecret,
        confirmParams: {
          return_url: `${window.location.origin}/#/CheckoutComplete`
        }
      })

      if(error)
        setErrorMessage(error.message)
    }

    return (
      <div className='checkoutContainer'>
        <img src={backButton} alt='backButton' onClick={() => navigate('/ProfilePage')}/>
        <h1>Checkout</h1>
        <h2>{currentUserInteraction.userInteractions} inteactions / ${(currentUserInteraction.amount / 100).toFixed(2)}</h2>
        <form className='checkoutForm' onSubmit={(e) => handleSubmit(e)}>
          <PaymentElement />
          <button type='submit' disabled={!stripe || !elements}>Checkout Now</button>
          {errorMessage && <div className='errorText'>{errorMessage}</div>}
        </form>
      </div>
    )
  }

  const options = {
    mode: 'payment',
    amount: currentUserInteraction.amount,
    currency: 'usd'
  }

  return (
    <Elements stripe={stripePromise} options={options}>
      <CheckoutForm />
    </Elements>
  )
}

export default Checkout